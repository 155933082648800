import { enableProdMode, StaticProvider } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from 'hmr';
import { API_URLS, IApiUrls } from 'app/main';
import { ApiUrls } from 'environments/environment.prod';
export function getApiUrls(): IApiUrls {
    return new ApiUrls();
}
const providers: StaticProvider[] = [
    { provide: API_URLS, useFactory: getApiUrls, deps: [] }
];


if (environment.production) {
    enableProdMode();
}

const bootstrap = () => platformBrowserDynamic(providers).bootstrapModule(AppModule);

if (environment.hmr) {
    if (module['hot']) {
        hmrBootstrap(module, bootstrap);
    }
    else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
}
else {
    bootstrap().catch(err => console.error(err));
}
