import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            // {
            //     id: 'sample',
            //     title: 'Sample',
            //     translate: 'NAV.SAMPLE.TITLE',
            //     type: 'item',
            //     icon: 'email',
            //     url: '/sample',
            //     badge: {
            //         title: '25',
            //         translate: 'NAV.SAMPLE.BADGE',
            //         bg: '#F44336',
            //         fg: '#FFFFFF'
            //     }
            // },
            // {
            //     id: 'home',
            //     title: 'Home',
            //     type: 'item',
            //     url: '/home',
            //     icon: 'home',
            // },
            {
                id: 'forms',
                title: 'Forms',
                type: 'item',
                url: '/forms',
                icon: 'home',
            },
            {
                id: 'apps',
                title: 'Apps',
                type: 'item',
                url: '/apps',
                icon: 'apps',
                hidden: true
            },
            {
                id: 'organization',
                title: 'Organizations',
                type: 'item',
                url: '/organizations',
                icon: 'business',
                hidden: true
            },
            {
                id: 'settings',
                title: 'Settings',
                icon: 'settings',
                type: 'collapsable',
                hidden: true,
                children: [
                    {
                        id: 'categories',
                        title: 'App Categories',
                        type: 'item',
                        url: '/apps/categories',
                        icon: 'home'
                    }
                ]
            }
        ]
    }
];
