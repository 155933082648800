import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { API_URLS, IApiUrls } from '..';
import { TokenService } from './token.service';
import { tap } from 'rxjs/operators';
import { TokenKeys } from '.';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  private baseUrl: string;
  onRoleChanges: BehaviorSubject<string[]>;

  onLoggingInChanged: BehaviorSubject<boolean>;
  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    @Inject(API_URLS) urls: IApiUrls) {
    this.baseUrl = urls.account;
    this.onLoggingInChanged = new BehaviorSubject<any>(null);
    this.onRoleChanges = new BehaviorSubject<any>(this.tokenService.getRoles());
  }

  login(form): Observable<any> {
    return this.http.post(this.baseUrl, form)
      .pipe(tap(event => {
        if (event.success) {
          this.onLoggingInChanged.next(true);
          this.tokenService.store(TokenKeys.accessToken, event.accessToken);
          this.tokenService.store(TokenKeys.expires, event.expires);
          this.tokenService.store(TokenKeys.user, JSON.stringify(event.user));
          this.tokenService.store(TokenKeys.roles, JSON.stringify(event.user.roles));
          this.onRoleChanges.next(event.user.roles);
        }
      }));
  }

  get user(): any {
    const user = this.tokenService.getByKey(TokenKeys.user);
    if (!user) {
      //  this.onLoggingInChanged.next(false);
    }
    return user ? JSON.parse(user) : null;
  }
  changePassword(form): Observable<any> {
    return this.http.post(this.baseUrl + 'change', form);
  }

  get isAuthenticated(): boolean {
    return this.tokenService.isValid;
  }

  logout(): void {
    this.tokenService.clear();
    this.onLoggingInChanged.next(false);
  }

  updateRoles(): void {
    this.onRoleChanges.next(this.tokenService.getRoles());
  }
}
