import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor } from './loader.interceptor';
import { ToastrInterceptor } from './toastr.interceptor';
import { TokenInterceptor } from './token.interceptor';
import { HeaderInterceptor } from './header.interceptor';

export const httpInterceptors = [
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ToastrInterceptor, multi: true }
];
