import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadingStrategy, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './authentication/auth.guard';

const routes: Routes = [
  {
    path: '', redirectTo: 'forms/home/drafts', pathMatch: 'full'
  },
  {
    path: 'forms',
    loadChildren: () => import('./forms/forms.module')
      .then(m => m.WfFormsModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'apps',
    loadChildren: () => import('./apps/apps-builder.module')
      .then(m => m.AppsBuilderModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'organizations',
    loadChildren: () => import('./organizations/organizations.module')
      .then(m => m.OrganizationsModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./authentication/auth.module')
      .then(m => m.AuthModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module')
      .then(m => m.ProfileModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],  //, { preloadingStrategy: PreloadAllModules }
  exports: [RouterModule]
})
export class AppRoutingModule { }
