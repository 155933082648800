import { IApiUrls } from 'app/main';

export const environment = {
  production: true,
  hmr: false
};
export const domainPath = 'http://localhost:5001/'; // >> api ip-address domain in production

export class ApiUrls implements IApiUrls {
  countries = domainPath + 'api/Countries/';
  languages = domainPath + 'api/languages/';
  cities = domainPath + 'api/cities/';
  formsRequiredApproval = domainPath + 'api/formRequiredApproval/';
  account = domainPath + 'api/account/';
  forms = domainPath + 'api/forms/';
  formsCategory = domainPath + 'api/formCategories/';
  apps = domainPath + 'api/appsbuilder/';
  organizations = domainPath + 'api/OrganizationBuilder/';
  users = domainPath + 'api/users/';
}
