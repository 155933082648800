import { Injectable } from '@angular/core';
import { TokenKeys } from '.';

@Injectable({
  providedIn: 'root'
})
export class TokenService {


  constructor() { }

  getByKey(key: TokenKeys): string {
    return localStorage.getItem(key);
  }
  getToken(): string {
    if (window) {
      return localStorage.getItem(TokenKeys.accessToken);
    }
  }

  getRoles(): string[] {
    if (window) {
      if (localStorage.getItem(TokenKeys.roles)) {
        return JSON.parse(localStorage.getItem(TokenKeys.roles));
      }
    }
    return [];
  }

  store(key: TokenKeys, value): void {
    if (window) {
      localStorage.setItem(key, value);
    }
  }

  clear(): void {
    if (window) {
      for (const key in TokenKeys) {
        if (TokenKeys.hasOwnProperty(key)) {
          localStorage.removeItem(key);
        }
      }
    }
  }
  get isValid(): boolean {
    if (window) {
      const expireAt = +localStorage.getItem(TokenKeys.expires);
      return (expireAt > Date.now() && this.getToken() != null);
    }
    return false;
  }
}
