import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableComponent } from './data-table.component';
import { RouterModule } from '@angular/router';
import { NgbdSortableHeader } from './sortable.directive';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    DataTableComponent,
    NgbdSortableHeader],
  imports: [
    
    CommonModule,
    
    MatTableModule,
    MatRippleModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatButtonModule,

    FuseSharedModule,
    RouterModule
  ],
  exports: [
    DataTableComponent
  ]
})
export class DataTableModule { }
