import { Component, OnInit, Input, Output, EventEmitter, QueryList, ViewChildren } from '@angular/core';
import { ITableSettings } from '.';
import swal from 'sweetalert2';
import { NgbdSortableHeader, SortEvent } from './sortable.directive';
import { PageEvent } from '@angular/material/paginator';
import { fuseAnimations } from '@fuse/animations';
export const compare = (v1, v2) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  animations: fuseAnimations
})
export class DataTableComponent implements OnInit {
  @Input() public cssClass = 'table table-bordered';
  _settings: ITableSettings;
  @Input() public set settings(value) {
    this._settings = value;
    this.displayedColumns = this._settings.columns
      .filter(c => !c.hidden)
      .map(c => c.fieldName);
  }
  public get settings(): ITableSettings {
    return this._settings;
  }
  @Output() public pageChanged = new EventEmitter<any>();
  @Output() public deleteEvent = new EventEmitter<number>();
  @Output() public checkEvent = new EventEmitter<any>();
  @Output() public actionEvent = new EventEmitter<any>();
  isLoading = false;
  hasDeleteSubscriper: boolean;
  page = 1;
  pageSize = 10;
  sortDirection: string;
  displayedColumns: any[];
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  colName: string;

  constructor() { }

  ngOnInit(): void {
    this.hasDeleteSubscriper = this.deleteEvent.observers.length > 0;
  }
  get keyColumn(): any {
    const col = this.settings.columns.find(d => d.isKey);
    return col ? col.fieldName : null;
  }

  loadPage(page: PageEvent): void {
    this.pageChanged.emit(page.pageIndex + 1);
  }
  deleteRow(id: number): void {
    swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to remove this item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#00B96F',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove!'
    })
      .then((result) => {
        if (result.value) {
          this.deleteEvent.emit(id);
        }
      });
  }
  checkChanged(item, obj: any): void {
    const formated: any = {};
    formated[obj.key] = obj.value;
    formated.id = obj.id;
    item[obj.key] = obj.value;
    this.checkEvent.emit(formated);
  }
  getValue(item, fieldName: string): string {
    if (item) {
      const cols = fieldName.split(',');
      for (const col of cols) {
        const fields = fieldName.split('.');
        if (fields.length > 1) {
          let value = '';
          // console.log(fields);
          for (const prop of fields) {
            // console.log(prop);
            value = item[prop];
            if (!value) {
              return '';
            }
            // console.log(value);
            item = value;
          }
          return value;
        }
      }
      return item[fieldName];
    }
    return '';
  }

  fireAction(item) {
    if (item) {
      this.actionEvent.emit(item);
    }
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    if (direction === '') {
      this.settings.data = this.settings.data;
    } else {
      this.settings.data = [...this.settings.data].sort((a, b) => {
        const res = compare(a[column], b[column]);
        this.sortDirection = direction;
        this.colName = column;
        return direction === 'asc' ? res : -res;
      });
    }
  }
}
