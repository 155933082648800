import { Injectable } from '@angular/core';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private _isLoading: boolean;
  constructor(private _fuseSplashScreenService: FuseSplashScreenService) { }


  get isLoading(): boolean{
    return this._isLoading;
  }

  set isLoading(value) {
    // if (value) {
    //   this._fuseSplashScreenService.show();
    // } else {
    //   this._fuseSplashScreenService.hide();
    // }
    this._isLoading = value;
  }
}
