import { FormGroup } from '@angular/forms';

export enum TokenKeys {
    accessToken = 'accessToken',
    expires = 'expires',
    user = 'user',
    roles = 'roles'
}

export const compareValidator = (key1: string, key2: string) => {
    return (group: FormGroup): { [key: string]: any } | null => {
        const control1 = group.get(key1);
        const control2 = group.get(key2);
        return control1.value === control2.value ? null : {
            compareInvalid: true
        };
    };
};
