import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from '../authentication/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private tokenService: TokenService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log(this.route);
        // if (!this.tokenService.isValid) {
        //     this.router.navigate(['/auth/login']);
        //     return null;
        // }
        const token = this.tokenService.getToken();
        const headers = token ? req.headers
            .set('Authorization', 'bearer ' + token) : req.headers;
        const authReq = req.clone({ headers });
        return next.handle(authReq)
            .pipe(tap(event => { }, error => {
                console.log(error);
                if (error.status === 401 || error.status === 403) {
                    this.tokenService.clear();
                    this.router.navigate(['auth/login']);
                }
            }));
    }
}