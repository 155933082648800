export const API_URLS = 'API_URLS';

export interface IApiUrls {
    users: string;
    countries: string;
    languages: string;
    cities: string;
    organizations: string;
    apps: string;
    formsRequiredApproval: string;
    formsCategory: string;
    account: string;
    forms: string;
}
